@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('./ck-editor.css');

.card.back {
  transform: rotateY(-180deg);
}

.react-date-picker__wrapper,
.react-datetime-picker__wrapper,
.react-time-picker__wrapper {
  border: none;
}

.y-divider {
  border-top: 0px solid rgba(0, 0, 0, 0.12);
  border-left: 0px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
  opacity: 0.25;
  background-color: transparent;
  width: 0.0625rem;
  height: 100%;
  margin: 0px auto;
  border-right: none;
  background-image: linear-gradient(
    rgba(52, 71, 103, 0),
    rgba(52, 71, 103, 0.4),
    rgba(52, 71, 103, 0)
  ) !important;
}

.x-divider {
  border-top: 0px solid rgba(0, 0, 0, 0.12);
  border-left: 0px solid rgba(0, 0, 0, 0.12);
  border-right: 0px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
  opacity: 0.25;
  background-color: transparent;
  height: 0.0625rem;
  width: 100%;
  margin: 0px auto;
  background-image: linear-gradient(
    to right,
    rgba(52, 71, 103, 0),
    rgba(52, 71, 103, 0.4),
    rgba(52, 71, 103, 0)
  ) !important;
}

.scrollable-content {
  /* Set the height and width for the scrollable area */
  overflow-y: scroll; /* Enable vertical scrollbar */
  /* Hide the scrollbar */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

/* For Firefox */
.scrollable-content::-webkit-scrollbar {
  display: none;
}

:root {
  --switches-bg-color: #eee8e8;
  --switches-label-color: black;
  --switch-bg-color: #456896;
  --switch-text-color: white;
}

.containerr {
  width: 20rem;
  padding: 1rem;
  margin-top: 1%;
}
/* p - decorative, not required */
.containerr p {
  margin-top: 2rem;
  font-size: 0.75rem;
  /* text-align: center; */
}

/* container for all of the switch elements 
  - adjust "width" to fit the content accordingly 
*/
.switches-container-noti {
  width: 33rem;
  position: relative;
  display: flex;
  padding: 0;
  position: relative;
  background: var(--switches-bg-color);
  line-height: 3rem;
  border-radius: 3rem;
}

/* input (radio) for toggling. hidden - use labels for clicking on */
.switches-container-noti input {
  visibility: hidden;
  position: absolute;
  top: 0;
}

/* labels for the input (radio) boxes - something to click on */
.switches-container-noti label {
  width: 50%;
  padding: 0;
  margin: 0;
  text-align: center;
  cursor: pointer;
  color: var(--switches-label-color);
}

/* switch highlighters wrapper (sliding left / right) 
  - need wrapper to enable the even margins around the highlight box
*/
.switch-wrapper-noti {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
  padding: 0.15rem;
  z-index: 3;
  transition: transform 0.5s cubic-bezier(0.77, 0, 0.175, 1);
  /* transition: transform 1s; */
}

/* switch box highlighter */
.switch {
  border-radius: 3rem;
  background: var(--switch-bg-color);
  height: 100%;
}

/* switch box labels
  - default setup
  - toggle afterwards based on radio:checked status 
*/
.switch div {
  width: 100%;
  text-align: center;
  opacity: 0;
  display: block;
  color: var(--switch-text-color);
  transition: opacity 0.2s cubic-bezier(0.77, 0, 0.175, 1) 0.125s;
  will-change: opacity;
  position: absolute;
  top: 0;
  left: 0;
}

/* slide the switch box from right to left */
.switches-container-noti input:nth-of-type(1):checked ~ .switch-wrapper-noti {
  transform: translateX(0%);
}

/* slide the switch box from left to right */
.switches-container-noti input:nth-of-type(2):checked ~ .switch-wrapper-noti {
  transform: translateX(100%);
}

/* toggle the switch box labels - first checkbox:checked - show first switch div */
.switches-container-noti
  input:nth-of-type(1):checked
  ~ .switch-wrapper-noti
  .switch
  div:nth-of-type(1) {
  opacity: 1;
}

/* toggle the switch box labels - second checkbox:checked - show second switch div */
.switches-container-noti
  input:nth-of-type(2):checked
  ~ .switch-wrapper-noti
  .switch
  div:nth-of-type(2) {
  opacity: 1;
}
